<template>
  <div class="contents">
    <CmsPage v-if="!pending && page" :page :components />

    <GenericPagePerson v-else-if="!pending && !page" :person />
  </div>
</template>

<script lang="ts" setup>
import type { Person } from '#gql/default'

interface Props {
  person: Person
}

defineProps<Props>()

const { pending, page, components } = await useCmsPageFetch()

defineOptions({
  name: 'PagePersonSlugIndex',
})
</script>
